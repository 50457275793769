import React, { useEffect } from "react"
import LayoutComponent from "../components/LayoutComponent/LayoutComponent"
import MenuComponent from "../components/MenuComponent/MenuComponent"
import SEO from "../components/seo"
import "../styles/global.scss"
import { unloadRecaptcha } from "../utils/Captcha"

const Impressum = () => {
  useEffect(() => {
    unloadRecaptcha()
  }, [])

  return (
    <LayoutComponent languageSwitchSubpage="impressum">
      <SEO title="Impressum" />
      <MenuComponent selected={0} />
      <h2 className="underline-headline a-little-bit-of-margin-top">
        Impressum
      </h2>
      <p>
        MTV 1846 e.V. Ludwigsburg
        <br />
        Abteilung Rollerderby
        <br />
        Bebenhäuser Straße 41
        <br />
        71638 Ludwigsburg
      </p>
      <p>
        Abteilungsleiter: Miriam Wilezich, Florian Böpple
        <br />
        Kassenwart: Sören Schneemann
      </p>
      <p>
        Angaben gemäß § 5 TMG Männerturnverein
        <br />
        (MTV) 1846 e.V. Ludwigsburg
        <br />
        Bebenhäuser Str. 41
        <br />
        71638 Ludwigsburg
      </p>
      <p>
        Vereinsregister: VR 200262
        <br />
        Registergericht: Amtsgericht Stuttgart
      </p>
      <p>
        Vertreten durch den Vorstand:
        <br />
        Jochen Eisele (1. Vorsitzender)
        <br />
        Franz Weckesser (2. Vorsitzender)
        <br />
        Claus Jarmer (Schatzmeister)
      </p>
      <p>
        Kontakt
        <br />
        Telefon: 07141 – 911880
        <br />
        Telefax: 07141 – 9118811
        <br />
        E-Mail:{" "}
        <a href="mailto:info@mtv-ludwigsburg.de">info@mtv-ludwigsburg.de</a>
      </p>
      <p>
        Umsatzsteuer-ID
        <br />
        Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
        DE146121555
      </p>
      <p>
        Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV MTV 1846 e.V.
        Ludwigsburg, Bebenhäuser Str. 41, 71638 Ludwigsburg. Wir sind nicht
        bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle teilzunehmen.
      </p>

      <h2 className="underline-headline a-little-bit-of-margin-top smaller-headline">
        Haftung
      </h2>
      <p>
        Die Inhalte dieser Website werden mit Sorgfalt erstellt. Wir übernehmen
        jedoch keine Haftung für die Richtigkeit, Vollständigkeit und Aktualität
        der bereitgestellten Inhalte.
      </p>

      <h2 className="underline-headline a-little-bit-of-margin-top smaller-headline">
        Urheberrecht
      </h2>
      <p>
        Texte, Bilder, Fotos und Filme sind grundsätzlich urheberrechtlich
        geschützt. Dies gilt auch dann, wenn kein Urheberrechtshinweis
        angebracht ist.
      </p>

      <h2 className="underline-headline a-little-bit-of-margin-top smaller-headline">
        Sonstiges
      </h2>
      <p className="left-side-margin a-little-bit-of-margin-top">
        Diese Seite nutzt PHP Mailer
      </p>

      <h2 className="underline-headline a-little-bit-of-margin-top smaller-headline">
        Technische Realisierung
      </h2>
      <p className="left-side-margin a-little-bit-of-margin-top">
        <a
          href="https://www.devduck.de"
          target="_blank"
          rel="noopener noreferrer"
        >
          DevDuck
        </a>
      </p>
    </LayoutComponent>
  )
}

export default Impressum
